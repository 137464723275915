<template>
    <div id="list">
        <div id="countainer">
            <div class="count-item" :class="{ max: max == c }" v-for="(c, m) in count" :key="c">
                <code>
                    <span>[{{ `${m.slice(-4, -2)}:${m.slice(-2)}` }}]</span>
                    {{ c }}
                </code>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CountList',
    props: {
        count: Object,
        max: Number
    }
}
</script>

<style scoped>
#countainer {
    display: flex;
    flex-direction: column-reverse;
}

.count-item {
    font-size: 3rem;
    line-height: 3rem;
}

.count-item:last-child {
    color: var(--current);
    font-size: 3.5rem;
    font-weight: bold;
}

.count-item.max {
    color: var(--max);
}

.count-item span {
    color: #666;
    font-size: 2rem;
}

.count-item.max span {
    color: var(--max);
}

.count-item:last-child span {
    color: #888;
}

/* Scrollbars */
::-webkit-scrollbar {
    width: 20px;
}
::-webkit-scrollbar-thumb {
    background-color: #000;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #444;
}
::-webkit-scrollbar-track {
    background-color: #222;
}
</style>