<template>
    <div>
        <div id="monitor">
            <div id="current" class="value-wrapper">
                <span>Current</span>
                <span>{{ current }}</span>
            </div>
            <div id="max" class="value-wrapper">
                <span>Max</span>
                <span>{{ max }}</span>
            </div>
            <div id="average" class="value-wrapper">
                <span>Average</span>
                <span>{{ average }}</span>
            </div>
        </div>
        <!-- <div v-if="token === ''">
            <a href="https://id.twitch.tv/oauth2/authorize?client_id=bkq1hocz0s48glbrqzbalgdk92ja2t&redirect_uri=http%3A%2F%2Flocalhost%3A8080&response_type=token&scope=channel:moderate">Login</a>
        </div> -->
        <!-- <code v-else>{{ channel }} {{ token }}</code> -->
    </div>
</template>

<script>
// import TwitchJs from 'twitch-js';

export default {
    name: 'CountMonitor',
    // data() {
    //     return {
    //         token: ''
    //     }
    // },
    props: {
        // channel: String,
        count: Object,
        max: Number,
        average: Number
    },
    created() {
        // Extract token
        // if(window.location.hash !== '') {
        //     let hash = window.location.hash.split('#')[1].split('&')[0].split('=');
        //     if(hash[0] == 'access_token') {
        //         this.token = hash[1];

                // Remove token from URL
                // history.pushState("", document.title, window.location.pathname);

                // try {
                    // const { chat } = new TwitchJs({token: this.token, username: this.channel});
                    // chat.connect().then(() => {
                    //     chat.join(this.channel).then(() => {
                    //         chat.slow(this.channel, 60);
                    //     });
                    // }).catch((e) => { console.error('Twitch error in Promise', e); });
                // } catch(e) {
                //     console.error('Twitch error', e);
                // }
            // }
        // }
    },
    computed: {
        current: function() {
            return this.count[Object.keys(this.count)[Object.keys(this.count).length - 1]];
        }
    }
}
</script>

<style scoped>
.value-wrapper {
    font-size: 4rem;
    display: flex;
    justify-content: space-between;
}

#current {
    color: var(--current);
    font-weight: bold;
}

#max {
    color: var(--max);
    font-weight: bold;
}

#average {
    color: #fff;
}
</style>