<template>
    <div id="chart">
        <div id="average-line" :style="{ top: averagePosition }"></div>
        <div id="bar-holder">
            <div class="bar" :class="{ max: max == c }" v-for="c in count" :key="c" :style="{ height: getHeight(c) }"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChartItem',
    props: {
        count: Object,
        max: Number,
        average: Number
    },
    computed: {
        averagePosition: function() {
            return `${this.average/this.max*100}%`;
        }
    },
    methods: {
        getHeight(c) {
            return (c/this.max*100) + '%';
        }
    }
}
</script>

<style scoped>
#bar-holder {
    padding: 1rem;
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
}

.bar {
    background-color: #888;
    width: 30px;
    min-height: 4px;
    margin-right: 6px;
}

.bar.max {
    background-color: var(--max);
}

.bar:last-child {
    background-color: var(--current);
    width: 60px;
}

#average-line {
    position: relative;
    height: 4px;
    background: #ffffff44;
    margin: -4px 0 0 0;
    padding: 0;
}
</style>