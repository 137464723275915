<template>
    <div id="grid">
        <div id="header">
            <h1>#{{ config.channel }}</h1>
        </div>
        <count-list id="list" :count="count" :max="max"></count-list>
        <count-monitor id="monitor" :count="count" :max="max" :average="average"></count-monitor>
        <chart-item id="chart" :count="count" :max="max" :average="average"></chart-item>
    </div>
</template>

<script>
import ChartItem from './components/ChartItem.vue';
import CountList from './components/CountList.vue';
import CountMonitor from './components/CountMonitor.vue';
import TwitchJs from 'twitch-js';

export default {
    name: 'App',
    components: {
        ChartItem,
        CountList,
        CountMonitor
    },
    data() {
        return {
            config: {
                channel: 'dialogikTV'
            },
            count: {}
        }
    },
    created() {
        // Parse URL param
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        
        // Parse channel parameter
        if(urlParams.has('channel')) {
            this.config.channel = urlParams.get('channel');
        }
        
        // Add target channel to page title
        document.title = `shlomo #${this.config.channel}`;

        let now = this.now();
        const count = this.count;
        count[now] = 0;
        setInterval(() => {
            now = this.now();

            // Increase
            if(!count[now]) {
                count[now] = 0;
            }
        }, 60 * 1000);

        // Initialize Twitch chat listener
        try {
            const { chat } = new TwitchJs({channel: this.config.channel});
            chat.connect().then(() => {
                chat.join(this.config.channel);
            }).catch((e) => { console.error('Twitch error in Promise', e); });
            chat.on(TwitchJs.Chat.Events.ALL, this.handleMessage);
        } catch(e) {
            console.error('Twitch error', e);
        }
    },
    computed: {
        max: function() {
            return Math.max(...Object.values(this.count));
        },
        average: function() {
            let average = (array) => array.reduce((a, b) => a + b) / array.length;
            return average(Object.values(this.count)).toFixed(1);
        }
    },
    methods: {
        handleMessage: function(message) {
            // Only handle user chat messages (no tech stuff)
            if(message.event != 'PRIVMSG') {
                // console.log(message);
                return;
            }
            
            // Skip if no message user or content (smells like no user chat message)
            if(typeof message.message == 'undefined' || typeof message.username == 'undefined') {
                return;
            }

            try {
                const now = this.now();

                // Increase
                if(this.count[now]) {
                    this.count[now]++;
                // ... or initalize
                } else {
                    this.count[now] = 1;
                }
            } catch(e) {
                console.log('Error handling Twitch message', e);
            }
        },
        // Get datetime-like string (YYYYMMDDHHmm)
        now: function() {
            return new Date().toISOString().replace(/[^0-9]/g, '').substr(0, 12);
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

/* Reset CSS */
*, *::before, *::after {
    box-sizing: border-box;
}

/* Color variables */
:root {
    --current: #00f7ff;
    --max: #ff00aa;
}

body {
    background-color: #000;
    color: #efefef;
    font-family: 'Fira Code', 'Courier new', monospace;
    letter-spacing: -2px;
    margin: 0;
}

a {
    color: #00acee;
    text-decoration: none;
}

a:hover {
    border-bottom: 1px solid #00acee;
}

#grid {
    display: grid;
    grid-template-areas:
        'header header'
        'list monitor'
        'chart chart'
    ;
    /* grid-gap: 0; */
    background-color: #222;
    height: 100vh;
    grid-template-rows: auto 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
}

#header {
    grid-area: header;
    padding: 0 1rem 0;
}

#header > h1 {
    font-size: 3rem;
}

#list {
    grid-area: list;
    background-color: #444;
    padding: 1rem;
    overflow-y: auto;
}

#monitor {
    grid-area: monitor;
    background-color: #666;
    padding: 1rem;
}

#chart {
    grid-area: chart;
    overflow-x: hidden;
}
</style>
